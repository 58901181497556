/**
* Generated automatically at built-time (2025-03-04T09:57:40.134Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "black-outdoor-kitchen",templateKey: "sites/101-f563f9ac-a1a1-4067-9e01-c8c95ee34963"};