/**
* Generated automatically at built-time (2025-03-04T09:57:40.076Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "quote-outdoor-kitchen",templateKey: "sites/101-fb3e3656-56e1-4df2-9bb9-7b0a4daea35a"};