/**
* Generated automatically at built-time (2025-03-04T09:57:39.981Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "induplus",templateKey: "sites/101-c1f890b7-72ba-46bc-af1e-7b21759b2cb3"};